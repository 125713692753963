@mixin egr-headings() {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    margin-bottom: 1em;
  }

  .h1 {
    @extend h1;
  }

  .h2 {
    @extend h2;
  }

  .h3 {
    @extend h3;
  }

  .h4 {
    @extend h4;
  }

  .h5 {
    @extend h5;
  }
}
