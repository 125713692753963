@use 'node_modules/@brz-scl/core/styles/themes/prebuilt/pigeon-blue-theme';
@use 'node_modules/@brz-scl/core/styles/themes/palette';
@use 'node_modules/@brz-scl/core/styles/utils/sr-only';
@use 'node_modules/@angular/cdk/overlay' as cdk-overlay;

@use 'styles/headings';

@include pigeon-blue-theme.scl-pigeon-blue-theme;
@include sr-only.scl-sr-only();
@include headings.egr-headings();
@include cdk-overlay.overlay();

body {
  // TODO: Add to global styles in SCL
  padding: 0;
  margin: 0;

  // TODO: Complex selectors (and a list of selectors) are not yet supported in most browsers:
  //  https://developer.mozilla.org/en-US/docs/Web/CSS/:not
  //  :not(nav ol, nav ul) li,
  scl-accordion li,
  p {
    line-height: 1.5;
  }

  button[scl-button] {
    white-space: normal !important;
    height: unset !important;
  }

  button:not(.scl-sm)[scl-button] {
    min-height: 2.5em;
  }

  button.scl-sm[scl-button] {
    min-height: 1.875em;
  }
}

// TODO: fix in scl
scl-modal {
  width: 101%;
}
